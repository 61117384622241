import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import LocalStorageService from "@/services/LocalStorageService";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true, scopes: ["admin"] },
  },
  {
    path: "/summary",
    name: "summary",
    component: () =>
      import(/* webpackChunkName: "manage" */ "../views/Summary"),
    meta: { requiresAuth: true, scopes: ["admin"] },
  },
  {
    path: "/manage",
    name: "manage",
    component: () =>
      import(/* webpackChunkName: "manage" */ "../views/manage/index.vue"),
    redirect: { name: "users-list" },
    meta: { requiresAuth: true, scopes: ["admin"] },
    children: [
      {
        path: "tarif",
        name: "tarif",
        component: () =>
          import(/* webpackChunkName: "tarif" */ "../views/manage/tarif"),
        meta: { requiresAuth: true, scopes: ["admin"] },
        children: [
          {
            path: ":tarif_id",
            name: "tariff-item",
            component: () =>
              import(
                /* webpackChunkName: "tarifItem" */ "../views/manage/createTarifForm.vue"
              ),
            props: true,
            meta: { requiresAuth: true, scopes: ["admin"] },
          },
        ],
      },
      {
        path: "users",
        name: "users-list",
        component: () =>
          import(
            /* webpackChunkName: "users-list" */ "../views/manage/UsersList.vue"
          ),
        meta: { requiresAuth: true, scopes: ["admin"] },
        children: [
          {
            path: ":userId",
            name: "user-view",
            component: () =>
              import(
                /* webpackChunkName: "users-list" */ "../views/manage/UserView.vue"
              ),
            props: true,
            meta: { requiresAuth: true, scopes: ["admin"] },
          },
        ],
      },
      {
        path: "settings",
        name: "settings",
        component: () => import("../views/manage/Settings.vue"),
        meta: { requiresAuth: true, scopes: ["admin"] },
        children: [
          {
            path: "batches",
            name: "batches",
            component: () => import("../views/manage/FileBatchesSettings.vue"),
            props: true,
            meta: { requiresAuth: true, scopes: ["admin"] },
          },
          {
            path: "upload",
            name: "upload",
            component: () => import("../views/manage/FileUploadSettings.vue"),
            props: true,
            meta: { requiresAuth: true, scopes: ["admin"] },
          },
        ],
      },
    ],
  },
  {
    path: "/files",
    name: "files",
    component: () =>
      import(/* webpackChunkName: "files" */ "../views/files/index.vue"),
    redirect: { name: "files-list", params: { state: 0 } },
    meta: { requiresAuth: true, scopes: ["admin"] },

    children: [
      {
        path: ":state",
        name: "files-list",
        component: () =>
          import(
            /* webpackChunkName: "files-list" */ "../views/files/FilesList.vue"
          ),
        props: true,
        meta: { requiresAuth: true, scopes: ["admin"] },
      },
      {
        path: ":state/:fileId",
        name: "file-view",
        component: () =>
          import(
            /* webpackChunkName: "file-view" */ "../views/files/FileView.vue"
          ),
        props: true,
        meta: { requiresAuth: true, scopes: ["admin"] },
      },
      {
        path: ":state/:fileId/full-audio",
        name: "file-view-full-audio",
        component: () =>
          import(
            /* webpackChunkName: "file-view" */ "../views/files/FileViewFull.vue"
          ),
        props: true,
        meta: { requiresAuth: true, scopes: ["admin"] },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const localStorageService = LocalStorageService.getService();

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorageService.getAccessToken() == null) {
      next({
        path: "/auth",
        params: { nextUrl: to.fullPath },
      });
    }
    next();
  } else {
    next();
  }
});

export default router;
