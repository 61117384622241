<template>
  <div v-if="!loading" class="h-full">
    <notification-container></notification-container>
    <privatetLayout v-if="isLoggedIn" />
    <publicLayout v-else />
  </div>
</template>

<script>
import privatetLayout from "@/layout/private";
import publicLayout from "@/layout/public";
import notificationContainer from "@/components/notification/notification-container.vue";
import { whoami } from "@/api/auth";
export default {
  components: {
    privatetLayout,
    publicLayout,
    notificationContainer,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/isLoggedIn"];
    },
  },
  async mounted() {
    const user = await whoami();
    this.loading = false;
    this.$store.commit("user/setUser", user);
  },
};
</script>
